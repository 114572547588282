import { AppstoreOutlined, CustomerServiceOutlined, FileOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  AppstoreOutlined,
  CustomerServiceOutlined,
  FileOutlined,
  SettingOutlined,
  UnorderedListOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const settings: NavItemType = {
  id: 'settings',
  type: 'group',
  title: 'Settings',
  children: [
    {
      id: 'account',
      title: 'Account',
      type: 'item',
      url: '/app/account',
      icon: icons.SettingOutlined
    },
    {
      id: 'inventory',
      title: 'Products & Services',
      type: 'item',
      url: '/app/inventory',
      icon: icons.AppstoreOutlined
    }
  ]
};

export default settings;
