import { ChromeOutlined, CustomerServiceOutlined, QuestionOutlined, ShoppingOutlined, StopOutlined, TeamOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  CustomerServiceOutlined,
  ShoppingOutlined,
  StopOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const users: NavItemType = {
  id: 'users',
  type: 'group',
  title: 'Users',
  children: [
    {
      id: 'customers',
      title: 'Customers',
      type: 'item',
      url: '/app/customers',
      icon: icons.CustomerServiceOutlined
    },
    {
      id: 'suppliers',
      title: 'Suppliers',
      type: 'item',
      url: '/app/suppliers',
      icon: icons.ShoppingOutlined
    },
    {
      id: 'staff',
      title: 'Staff',
      type: 'item',
      url: '/app/staff',
      icon: icons.TeamOutlined
    }
  ]
};

export default users;
