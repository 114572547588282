import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const purchases: NavItemType = {
  id: 'purchases',
  type: 'group',
  title: 'Purchases',
  children: [
    {
      id: 'purchase-orders',
      title: 'Purchase Orders',
      type: 'item',
      url: '/app/purchases/lpos'
    },
    {
      id: 'grns',
      title: 'GRNs',
      type: 'item',
      url: '/app/purchases/grns'
    },
    {
      id: 'supplier-invoices',
      title: 'Supplier Invoices',
      type: 'item',
      url: '/app/purchases/invoices'
    },
    {
      id: 'supplier-credit-notes',
      title: 'Supplier Credit Notes',
      type: 'item',
      url: '/app/purchases/credit-notes'
    }
  ]
};

export default purchases;
