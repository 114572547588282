import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const sales: NavItemType = {
  id: 'sales',
  type: 'group',
  title: 'Sales',
  children: [
    {
      id: 'cash sales',
      title: 'Cash Sales',
      type: 'item',
      url: '/app/sales/cash-sales'
    },
    {
      id: 'fee notes',
      title: 'Fee Notes',
      type: 'item',
      url: '/app/sales/fee-notes'
    },
    {
      id: 'sales-orders',
      title: 'Sales Orders',
      type: 'item',
      url: '/app/sales/orders'
    },
    {
      id: 'quotations',
      title: 'Sales Quotations',
      type: 'item',
      url: '/app/sales/quotations'
    },
    {
      id: 'invoices',
      title: 'Invoices',
      type: 'item',
      url: '/app/sales/invoices'
    },
    {
      id: 'deliveries',
      title: 'Sales Deliveries',
      type: 'item',
      url: '/app/sales/deliveries'
    },
    {
      id: 'credit-notes',
      title: 'Sales Credit Notes',
      type: 'item',
      url: '/app/sales/credit-notes'
    }
  ]
};

export default sales;
