import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const expenses: NavItemType = {
  id: 'accounting',
  type: 'group',
  title: 'Accounting',
  children: [
    {
      id: 'journals',
      title: 'Journal Entries',
      type: 'item',
      url: '/app/accounting/journal-entries'
    },
    {
      id: 'gl',
      title: 'General Ledger',
      type: 'item',
      url: '/app/accounting/gl'
    }
  ]
};

export default expenses;
