import { DashboardOutlined, FolderOutlined, QuestionOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const icons = {
  DashboardOutlined,
  FolderOutlined,
  QuestionOutlined,
  SettingOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin: NavItemType = {
  id: 'admin',
  type: 'group',
  title: 'Admin',
  children: [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/manage',
      icon: icons.DashboardOutlined
    },
    {
      id: 'Clients',
      title: 'Clients',
      type: 'item',
      url: '/manage/clients',
      icon: icons.FolderOutlined
    },
    {
      id: 'Users',
      title: 'Users',
      type: 'item',
      url: '/manage/users',
      icon: icons.TeamOutlined
    },
    {
      id: 'Settings',
      title: 'Settings',
      type: 'item',
      url: '/manage/settings',
      icon: icons.SettingOutlined
    }
  ]
};

export default admin;
