import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import LoaderRoutes from './LoaderRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import SetupRoutes from './SettingsRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
export const router = sentryCreateBrowserRouter([LoaderRoutes, MainRoutes, AdminRoutes, LoginRoutes, SetupRoutes]);
