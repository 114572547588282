import { BarChartOutlined, ChromeOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  ChromeOutlined,
  BarChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const main: NavItemType = {
  id: 'main',
  type: 'group',
  title: 'Dashboard',
  children: [
    {
      id: 'overview',
      title: 'Overview',
      type: 'item',
      url: '/app',
      icon: icons.ChromeOutlined
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/app/reports',
      icon: icons.BarChartOutlined
    }
  ]
};

export default main;
